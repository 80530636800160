




























import Component from "vue-class-component";
import { ModelListSelect } from "vue-search-select";
import Vue from "vue";
import { namespace } from "vuex-class";
import { TicketTemplate } from "@/models/ticket-template.model";
import { PaginatedResult } from "@/shared/types/paginated-result.class";

const userNamespace = namespace("Users");
const ticketTemplatesNamespace = namespace("TicketTemplates");

@Component({
  components: {
    ModelListSelect,
  },
})
export default class GenerateTicketForGroup extends Vue {
  selectedGroup = "";
  selectedTicketTemplate = "";
  isLoading = false;

  @ticketTemplatesNamespace.Getter("all")
  ticketTemplates!: PaginatedResult<TicketTemplate>;

  @userNamespace.Getter("groups")
  groups!: string[];

  @ticketTemplatesNamespace.Action("fetchAll")
  fetchTicketTemplates!: () => Promise<TicketTemplate[]>;

  @ticketTemplatesNamespace.Action("generateForGroup")
  generateForGroup!: (payload: {
    group: string;
    template_id: string;
  }) => Promise<void>;

  @userNamespace.Action("fetchGroups")
  fetchGroups!: () => Promise<string[]>;

  get ticketTemplatesData() {
    return this.ticketTemplates?.data;
  }

  async generateTicket() {
    this.isLoading = true;
    const payload = {
      group: this.selectedGroup,
      template_id: this.selectedTicketTemplate,
    };
    await this.generateForGroup(payload);
    this.isLoading = false;
  }
  async created() {
    this.isLoading = true;
    await this.fetchGroups();
    await this.fetchTicketTemplates();
    this.isLoading = false;
  }
}
